import 'bootstrap/dist/css/bootstrap.min.css';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import { BackgroundColor } from '../components/PageBackgroundColor';
import ProtectedRoute from '../components/ProtectedRoute';
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';

const Company = lazy(() => import('../pages/Company'));
const AddCompany = lazy(() => import('../pages/Company/AddCompany'));
const EditCompany = lazy(() => import('../pages/Company/EditCompany'));
const CompanyNotFound = lazy(() => import('../pages/CompanyNotFound'));
const Inspection = lazy(() => import('../pages/Inspection'));
const AddInspection = lazy(() => import('../pages/Inspection/AddInspection'));
const EditInspection = lazy(() => import('../pages/Inspection/EditInspection'));
const Profile = lazy(() => import('../pages/Profile'));
const ProductionUnit = lazy(() => import('../pages/ProductionUnit'));
const AddProductionUnit = lazy(() => import('../pages/ProductionUnit/AddProductionUnit'));
const EditProductionUnit = lazy(() => import('../pages/ProductionUnit/EditProductionUnit'));
const Asset = lazy(() => import('../pages/Asset'));
const AddAsset = lazy(() => import('../pages/Asset/AddAsset'));
const Library = lazy(() => import('../pages/Library'));
const AddModel = lazy(() => import('../pages/Library/AddModel'));
const EditModel = lazy(() => import('../pages/Library/EditModel'));
const AssetAddInspection = lazy(() => import('../pages/Asset/AddInspection'));
const SafetyDevice = lazy(() => import('../pages/SafetyDevice'));
const AddSafetyDevice = lazy(() => import('../pages/SafetyDevice/AddSafetyDevice'));
const EditSafetyDevice = lazy(() => import('../pages/SafetyDevice/EditSafetyDevice'));

interface IRouterConfigProps {
    isMenuMinimized: boolean,
}

class RouterConfig extends React.Component<IRouterConfigProps> {
    render() {
        // const className : CSSProperties = { marginLeft: this.props.isMenuMinimized ? '56px' : '142px', width:'auto', overflowX:'hidden' };
        const className = this.props.isMenuMinimized ? 'min-menu' : 'max-menu';

        return (
            <div id='main-panel' className={`col p-0 fill-height ${className}`} >
                <Suspense fallback={<LoadingIndicator />}>
                    <Routes>
                        <Route index element={<ProtectedRoute component={Home} />} />
                        <Route path='/profile' element={<ProtectedRoute component={Profile} />} />
                        <Route path='/company-not-found'element={<ProtectedRoute component={CompanyNotFound} />} />

                        <Route path='/company/add' element={
                            <ProtectedRoute component={AddCompany} permissions={['CanAddClient']} backgroundColor={BackgroundColor.CompanyPage}  />
                        } />
                        <Route path='/company/edit/:moniker' element={
                            <ProtectedRoute component={EditCompany} permissions={['CanEditClient']}  backgroundColor={BackgroundColor.CompanyPage}  />
                        } />
                        <Route path='/company/:moniker' element={
                            <ProtectedRoute component={Company} permissions={['CanReadClient']} backgroundColor={BackgroundColor.CompanyPage} />
                        } />

                        <Route path='/production-unit/:productionLineId' element={
                            <ProtectedRoute component={ProductionUnit} permissions={['CanReadProductionLine']} backgroundColor={BackgroundColor.ProductionUnitPage} />
                        } />
                        <Route path='/production-unit/edit/:productionLineId' element={
                            <ProtectedRoute component={EditProductionUnit} permissions={['CanEditProductionLine']} backgroundColor={BackgroundColor.ProductionUnitPage} />
                        } />
                        <Route path='/production-unit/add/:companyMoniker' element={
                            <ProtectedRoute component={AddProductionUnit} permissions={['CanAddProductionLine']} backgroundColor={BackgroundColor.ProductionUnitPage} />
                        } />

                        <Route path='/asset/:assetId' element={
                            <ProtectedRoute component={Asset} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanReadAsset']} backgroundColor={BackgroundColor.AssetPage} />
                        } />
                        <Route path='/asset/add/:productionLineId' element={
                            <ProtectedRoute component={AddAsset} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanAddAsset']} backgroundColor={BackgroundColor.AssetPage} />
                        } />
                        <Route path='/asset/:assetId/add-inspection' element={
                            <ProtectedRoute component={AssetAddInspection} permissions={['CanAddInspection']} backgroundColor={BackgroundColor.AssetPage} />
                        } />

                        <Route path='/library' element={
                            <ProtectedRoute component={Library} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanReadSampleModel']} backgroundColor={BackgroundColor.DefaultPage}  />
                        } />
                        <Route path='/library/add' element={
                            <ProtectedRoute component={AddModel} permissions={['CanAddSampleModel']} backgroundColor={BackgroundColor.DefaultPage}  />
                        } />
                        <Route path='/library/edit/:sampleModelId' element={
                            <ProtectedRoute component={EditModel} permissions={['CanEditSampleModel']} backgroundColor={BackgroundColor.DefaultPage} />
                        } />

                        <Route path='/inspection/:inspectionId'  element={
                            <ProtectedRoute component={Inspection} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanReadInspection']} backgroundColor={BackgroundColor.InspectionPage} />
                        } />
                        <Route path='/inspection/add/:assetId' element={
                            <ProtectedRoute component={AddInspection} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanAddInspection']} backgroundColor={BackgroundColor.InspectionPage} />
                        } />
                        <Route path='/inspection/add/:assetId/category/:categoryId' element={
                            <ProtectedRoute component={AddInspection} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanAddInspection']} backgroundColor={BackgroundColor.InspectionPage} />
                        } />
                        <Route path='/inspection/add/:assetId/previous-inspection/:previousInspectionId' element={
                            <ProtectedRoute component={AddInspection} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanAddInspection']} backgroundColor={BackgroundColor.InspectionPage} />
                        } />
                        <Route path='/inspection/edit/:inspectionId' element={
                            <ProtectedRoute component={EditInspection} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanEditInspection']} backgroundColor={BackgroundColor.InspectionPage} />
                        } />
                        <Route path='/safety-device/:assetId' element={
                            <ProtectedRoute component={SafetyDevice} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanReadAsset']} backgroundColor={BackgroundColor.AssetPage} />
                        } />
                        <Route path='/safety-device/add/:assetId' element={
                            <ProtectedRoute component={AddSafetyDevice} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanEditAsset']} backgroundColor={BackgroundColor.AssetPage} />
                        } />
                        <Route path='/safety-device/edit/:safetyDeviceId' element={
                            <ProtectedRoute component={EditSafetyDevice} isMenuMinimized={this.props.isMenuMinimized} permissions={['CanReadAsset']} backgroundColor={BackgroundColor.AssetPage} />
                        } />
                        <Route path='/page-not-found' element={
                            <ProtectedRoute component={PageNotFound} />
                        } />

                        <Route element={
                            <ProtectedRoute component={PageNotFound} />
                        } />
                    </Routes>
                </Suspense>
            </div>
        );
    }
}

export { RouterConfig };
