import { ApiException } from '../api/vincotte.via.api';

export class ApiExceptionHelper {
    static getMessage(apiException: ApiException ) : string | Array<string> {
        if (apiException.result && (apiException.result.errors || apiException.result.title)) {
            if (!apiException.result.errors) {
                return apiException.result.title;
            }

            if (typeof apiException.result.errors === 'object') {
                return Object.values(apiException.result.errors);
            }
        }

        if (!apiException.response) {
            return apiException.message;
        }

        if (typeof apiException.response === 'object') {
            return Object.values(apiException.response);
        }

        return apiException.response;
    }
}