export class StringHelper {
    static truncate(str: string, n: number, suffix: string = '...') : string {
        return (str.length > n) ? `${str.substring(0, n - suffix.length)}${suffix}` : str;
    }

    static getFileName(mediaLink: URL | string, removeExtension: boolean = false): string {
        const link = decodeURIComponent(mediaLink instanceof URL ? mediaLink.pathname : mediaLink);

        let name = link.split('\\').pop();
        name = name?.split('?').shift();
        name = name?.split('/').pop();

        if (name && removeExtension) {
            name = StringHelper.getFileName(name.split('.').slice(0, -1).join('.'));
        }

        return name ? name : '';
    }

    static removeSpecialCharacters(string: string): string {
        return string.replace(/[^A-Za-z0-9\-._ ]/g, '_');
    }
}
